import { ErrorPayload } from "@ob/api/utils/safeFetch";
import { createAction } from "@reduxjs/toolkit";
import { CreditCardDbType, BankAcctDbType } from "@ob/redux/types/dbTypes";
import type { BankInfoReqType } from "@ob/api/types";

const vendorPayDestActions = {
  apiFetching: createAction(
    "vendor/payDest/apiFetching",
    (fetching: boolean) => ({
      payload: { fetching },
    }),
  ),
  apiError: createAction("vendor/payDest/apiError", (error: ErrorPayload) => ({
    payload: { error },
  })),
  apiSuccess: createAction("vendor/payDest/apiSuccess", (success: boolean) => ({
    payload: { success },
  })),
  getCardReqParams: createAction("vendor/payDest/getCardReqParams"),
  setCardReqParams: createAction(
    "vendor/payDest/setCardReqParams",
    (payload: {
      userId?: string;
      businessProfileId?: string;
      userIntentId?: string;
      clientId: string;
      sessionToken: string;
    }) => ({ payload }),
  ),
  clearCardReqParams: createAction("vendor/payDest/clearCardReqParams"),
  getPaymentDests: createAction("vendor/payDest/getPaymentDests"),
  setCards: createAction(
    "vendor/payDest/setCards",
    (cards: CreditCardDbType[]) => ({ payload: { cards } }),
  ),
  deleteCard: createAction(
    "vendor/payDest/deleteCard",
    (id: string, redirect: string = "") => ({
      payload: { id, redirect },
    }),
  ),
  updateCard: createAction("vendor/payDest/updateCard", (id: string) => ({
    payload: { id },
  })),
  submitBankAcct: createAction(
    "vendor/payDest/submitBankAcct",
    (info: BankInfoReqType) => ({
      payload: { info },
    }),
  ),
  setBankAccts: createAction(
    "vendor/payDest/setBankAccts",
    (banks: BankAcctDbType[]) => ({ payload: { banks } }),
  ),
  deleteBankAcct: createAction(
    "vendor/payDest/deleteBankAcct",
    (id: string, redirect: string = "") => ({
      payload: { id, redirect },
    }),
  ),
  updateBankAcct: createAction(
    "vendor/payDest/updateBankAcct",
    (id: string) => ({ payload: { id } }),
  ),
  notifyAcctStatusChange: createAction("vendor/payDest/notifyAcctStatusChange"),
  deleteDisabledCard: createAction(
    "vendor/payDest/deleteDisabledCard",
    (nextScreen: string) => ({
      payload: { nextScreen },
    }),
  ),
  deleteDisabledBankAccount: createAction(
    "vendor/payDest/deleteDisabledBankAccount",
    (nextScreen: string) => ({
      payload: { nextScreen },
    }),
  ),
};
export default vendorPayDestActions;
