import { useState, useEffect, useCallback } from "react";
import {
  Autocomplete,
  Stack,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import type { BankInfoReqType } from "@ob/api/types";
import type { AccountSelectType } from "@ob/layouts/VendorOnboarding/types/payDest";
import { useNavigate } from "react-router";
import { useAppSelector, useAppDispatch } from "@ob/redux/store";
import { FadeInStack } from "@ob/components/FadeComponents";
import va from "@ob/layouts/VendorOnboarding/redux/actions";
import GenericRetryError from "@ob/components/GenericRetryError";
import LocalErrorMsg from "@ob/components/LocalErrorMsg";
import StitchAsyncButton from "@ob/components/StitchAsyncButton";
import { selectMerchantName } from "@ob/layouts/VendorOnboarding/redux/selectors/auth";
import {
  selectPayDestError,
  selectPayDestFetching,
  selectPayDestSuccess,
} from "@ob/layouts/VendorOnboarding/redux/selectors/payDest";
import {
  validateAccount,
  validateRouting,
  validateAccountType,
} from "./utils/validation";
import accountTypes from "./utils/accountTypes";

export default function NewBankAccount() {
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const navigate = useNavigate();
  const merchantName = useAppSelector(selectMerchantName);
  const [showErrors, setShowErrors] = useState(false);
  const [uiErrors, setUiErrors] = useState({
    accountNumber: "",
    routingNumber: "",
    accountType: "",
  });

  const hasErrors = [
    uiErrors.accountNumber,
    uiErrors.routingNumber,
    uiErrors.accountType,
  ].some(Boolean);

  const [accountNumber, setAccountNumber] = useState("");
  const [routingNumber, setRoutingNumber] = useState("");
  const [accountType, setAccountType] = useState<AccountSelectType | null>(
    null,
  );

  const apiFetching = useAppSelector(selectPayDestFetching);
  const apiSuccess = useAppSelector(selectPayDestSuccess);
  const apiError = useAppSelector(selectPayDestError);

  const [uiFetching, setUiFetching] = useState(false);

  const handleValidationResult = (name: string) => (error: string) => {
    if (error) {
      setUiErrors((prev) => ({ ...prev, [name]: error }));
    } else {
      if (!hasErrors) {
        setShowErrors(false);
      }
      setUiErrors((prev) => ({ ...prev, [name]: "" }));
    }
  };

  const handleSubmit = () => {
    const accountIsValid = validateAccount(
      accountNumber,
      handleValidationResult("account"),
    );
    const routingIsValid = validateRouting(
      routingNumber,
      handleValidationResult("routing"),
    );
    const accountTypeIsValid = validateAccountType(
      accountType?.name,
      handleValidationResult("accountType"),
    );

    if (!accountIsValid || !routingIsValid || !accountTypeIsValid) {
      setShowErrors(true);
      return;
    }

    if (!apiFetching) {
      const bankInfo: BankInfoReqType = {
        accountNumber,
        routingNumber,
        accountType: accountType!.name,
      };
      dispatch(va.payDest.submitBankAcct(bankInfo));
      setUiFetching(true);
    }
  };

  const handleValidation = (name: string, value: string): boolean => {
    switch (name) {
      case "accountNumber":
        return validateAccount(value, handleValidationResult(name));
      case "routingNumber":
        return validateRouting(value, handleValidationResult(name));
      case "accountType":
        return validateAccountType(value, handleValidationResult(name));
      default:
        break;
    }
    return false;
  };

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    handleValidation(name, value);
    if (name === "accountNumber") {
      setAccountNumber(value);
    } else if (name === "routingNumber") {
      setRoutingNumber(value);
    } else if (name === "accountType") {
      setAccountType(accountTypes.find((s) => s.name === value) || null);
    }
  };

  const handleOnBlur = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    const valid = handleValidation(name, value);
    if (!valid) {
      setShowErrors(true);
    }
  };

  const handleOnKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const allowSubmit = [
      !uiErrors.accountNumber,
      !uiErrors.routingNumber,
      !uiErrors.accountType,
      accountNumber,
      routingNumber,
      accountType,
      !apiFetching,
      e.key === "Enter",
    ].every(Boolean);
    if (allowSubmit) {
      handleSubmit();
    }
  };

  const clearAsyncState = useCallback(() => {
    dispatch(va.payDest.apiSuccess(false));
    dispatch(va.payDest.apiFetching(false));
    dispatch(
      va.payDest.apiError({
        status: 0,
        message: "",
      }),
    );
  }, []);

  useEffect(() => {
    clearAsyncState();
    return () => {
      clearAsyncState();
    };
  }, []);

  useEffect(() => {
    if (apiSuccess && uiFetching) {
      dispatch(va.payDest.apiSuccess(false));
      setUiFetching(false);
      navigate("/success");
    }
  }, [apiSuccess]);

  useEffect(() => {
    if (!hasErrors && showErrors) {
      setShowErrors(false);
    }
  }, [hasErrors]);

  return (
    <FadeInStack
      justifyContent="space-between"
      gap={1}
      sx={{
        p: 3,
        maxWidth: "748px",
        flexGrow: 1,
      }}
    >
      <Stack>
        <Typography variant="h2">Connect Bank</Typography>
        <Typography variant="body1">
          Link a bank account to {merchantName} for transfers.
        </Typography>
      </Stack>
      <Stack data-testid="new-bank-account" gap={1}>
        <Stack>
          <input
            disabled={apiFetching}
            type="number"
            placeholder="Account Number"
            name="accountNumber"
            value={accountNumber}
            onChange={handleOnChange}
            onBlur={handleOnBlur}
            onInput={() =>
              setUiErrors((prev) => ({ ...prev, accountNumber: "" }))
            }
            onKeyDown={handleOnKeyDown}
            className="accountNumber-input"
            style={{
              color: theme.palette.primary.main,
              border: `1px solid ${
                uiErrors.accountNumber && showErrors
                  ? theme.palette.error.main
                  : theme.palette.secondary.light
              }`,
              backgroundColor: "transparent",
              borderRadius: "4px",
              height: "40px",
              fontSize: "16px",
              lineHeight: "24px",
              fontFamily: "CircularRegular",
              paddingLeft: "10px",
              width: "100%",
            }}
          />
          <Typography
            variant="subtitle1"
            color="error"
            sx={{
              pt: "5px",
              pl: "10px",
              display: showErrors && uiErrors.accountNumber ? "block" : "none",
              lineHeight: "16px",
            }}
          >
            {uiErrors.accountNumber}
          </Typography>
        </Stack>
        <Stack>
          <input
            disabled={apiFetching}
            type="number"
            placeholder="Routing Number"
            name="routingNumber"
            value={routingNumber}
            onChange={handleOnChange}
            onBlur={handleOnBlur}
            onInput={() =>
              setUiErrors((prev) => ({ ...prev, routingNumber: "" }))
            }
            onKeyDown={handleOnKeyDown}
            className="routingNumber-input"
            style={{
              color: theme.palette.primary.main,
              border: `1px solid ${
                uiErrors.routingNumber && showErrors
                  ? theme.palette.error.main
                  : theme.palette.secondary.light
              }`,
              backgroundColor: "transparent",
              borderRadius: "4px",
              height: "40px",
              fontSize: "16px",
              lineHeight: "24px",
              fontFamily: "CircularRegular",
              paddingLeft: "10px",
              width: "100%",
            }}
          />
          <Typography
            variant="subtitle1"
            color="error"
            sx={{
              pt: "5px",
              pl: "10px",
              display: showErrors && uiErrors.routingNumber ? "block" : "none",
              lineHeight: "16px",
            }}
          >
            {uiErrors.routingNumber}
          </Typography>
        </Stack>
        <Stack>
          <Autocomplete
            id="state"
            disableClearable
            onChange={(_, value) => {
              handleOnChange({
                target: {
                  name: "accountType",
                  value: value?.name || "",
                },
              } as React.ChangeEvent<HTMLInputElement>);
            }}
            onInputChange={(_, value, reason) => {
              if (reason === "reset") {
                const targetAcct = accountTypes.find(
                  (s) => s.name === value.toLowerCase(),
                );
                handleOnChange({
                  target: {
                    name: "accountType",
                    value: targetAcct?.name || "",
                  },
                } as React.ChangeEvent<HTMLInputElement>);
              }
            }}
            // @ts-expect-error - MUI types are incorrect
            value={accountType}
            options={accountTypes.sort((a, b) => -b.name.localeCompare(a.name))}
            isOptionEqualToValue={(option, value) => option.name === value.name}
            getOptionLabel={(option) =>
              `${option.name[0].toUpperCase()}${option.name.slice(1)}`
            }
            renderOption={(props, option) => (
              <li {...props} key={option.name} data-option-name={option.name}>
                {`${option.name[0].toUpperCase()}${option.name.slice(1)}`}
              </li>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder="Account Type"
                style={{
                  color: theme.palette.primary.main,
                  backgroundColor: "transparent",
                  borderRadius: "4px",
                  fontSize: "16px",
                  lineHeight: "24px",
                  fontFamily: "CircularRegular",
                  width: "100%",
                }}
              />
            )}
          />
          <Typography
            variant="subtitle1"
            color="error"
            sx={{
              pt: "5px",
              pl: "10px",
              display: showErrors && uiErrors.accountType ? "block" : "none",
              lineHeight: "16px",
            }}
          >
            {uiErrors.accountType}
          </Typography>
        </Stack>
      </Stack>
      <Stack gap={2}>
        <GenericRetryError error={apiError} onClick={handleSubmit} />
        <LocalErrorMsg error={apiError} />
        <StitchAsyncButton
          buttonText="Submit"
          variant="contained"
          color="primary"
          logoColor="white"
          onClick={() => handleSubmit()}
          success={apiSuccess}
          loading={apiFetching}
          loadingSize="small"
          loadingPosition={{ top: -31, left: 0 }}
        />
      </Stack>
    </FadeInStack>
  );
}
