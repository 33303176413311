import { ErrorPayload } from "@ob/api/utils/safeFetch";
import { FeesPayloadType } from "@ob/layouts/VendorOnboarding/types/config";
import { createAction } from "@reduxjs/toolkit";

const vendorConfigActions = {
  apiFetching: createAction(
    "vendor/config/apiFetching",
    (fetching: boolean) => ({
      payload: { fetching },
    }),
  ),
  apiError: createAction("vendor/config/apiError", (error: ErrorPayload) => ({
    payload: { error },
  })),
  apiSuccess: createAction("vendor/config/apiSuccess", (success: boolean) => ({
    payload: { success },
  })),
  getConfig: createAction("vendor/config/getConfig"),
  setKybEnabled: createAction(
    "vendor/config/setKybEnabled",
    (kybEnabled: boolean) => ({
      payload: { kybEnabled },
    }),
  ),
  setCardFees: createAction(
    "vendor/config/setCardFees",
    (fees: FeesPayloadType) => ({ payload: { fees } }),
  ),
  setBankFees: createAction(
    "vendor/config/setBankFees",
    (fees: FeesPayloadType) => ({ payload: { fees } }),
  ),
};

export default vendorConfigActions;
