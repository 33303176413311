import { createAsyncSlice } from "@ob/redux/types";
import { createReducer } from "@reduxjs/toolkit";
import { ConfigStateType } from "@ob/layouts/VendorOnboarding/types/config";
import va from "@ob/layouts/VendorOnboarding/redux/actions";

export const initConfigState = createAsyncSlice({
  kybEnabled: false,
  cardFees: null,
  bankFees: null,
});

const configReducer = createReducer<ConfigStateType>(
  initConfigState,
  (builder) => {
    builder
      .addCase(va.config.apiFetching, onApiFetching)
      .addCase(va.config.apiError, onApiError)
      .addCase(va.config.apiSuccess, onApiSuccess)
      .addCase(va.config.setKybEnabled, onSetKybEnabled)
      .addCase(va.config.setCardFees, onSetCardFees)
      .addCase(va.config.setBankFees, onSetBankFees);
  },
);
export default configReducer;

export function onApiFetching(
  state: ConfigStateType,
  action: ReturnType<typeof va.config.apiFetching>,
) {
  state.fetching = action.payload.fetching;
}

export function onApiError(
  state: ConfigStateType,
  action: ReturnType<typeof va.config.apiError>,
) {
  state.error = action.payload.error;
}

export function onApiSuccess(
  state: ConfigStateType,
  action: ReturnType<typeof va.config.apiSuccess>,
) {
  state.success = action.payload.success;
}

export function onSetKybEnabled(
  state: ConfigStateType,
  action: ReturnType<typeof va.config.setKybEnabled>,
) {
  state.data.kybEnabled = action.payload.kybEnabled;
}

export function onSetCardFees(
  state: ConfigStateType,
  action: ReturnType<typeof va.config.setCardFees>,
) {
  state.data.cardFees = action.payload.fees;
}

export function onSetBankFees(
  state: ConfigStateType,
  action: ReturnType<typeof va.config.setBankFees>,
) {
  state.data.bankFees = action.payload.fees;
}
