import { CriticalIdsType, DomainIdsType } from "./types";

export const Critical: CriticalIdsType = {
  // NOTE: See ./NOTES.md #1 for usecase info.
  LOCAL_API_ERROR: "Local-Api-Error",
  UNCAUGHT_FRONTEND_ERROR: "Uncaught-Error",
  ERROR_BOUNDARY: "Error-Boundary",
};

export const Domain: DomainIdsType = {
  // NOTE: See ./NOTES.md #2 for usecase info.
  API: {
    default: "Api",
    safeFetch: "Api-Safe-Fetch",
  },
  SAGAS: {
    default: "Sagas",
    auth: "Sagas-Auth",
  },
  REDUCERS: {
    default: "Reducers",
    auth: "Reducers-Auth",
  },
  SELECTORS: {
    default: "Selectors",
    auth: "Selectors-Auth",
  },
  COMPONENTS: {
    default: "Components",
    login: "Components-Login",
  },
};

type TracingIDs = {
  critical: CriticalIdsType;
  domains: DomainIdsType;
};

export default TracingIDs;
