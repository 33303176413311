import { useEffect, useState } from "react";
import { Stack, Box, Typography, Button } from "@mui/material";
import fingerPoint from "@ob/assets/svgs/finger-point.svg";
import { useLocation, useNavigate } from "react-router";
import { FadeInStack } from "@ob/components/FadeComponents";
import { useAppDispatch, useAppSelector } from "@ob/redux/store";
import ob from "@ob/layouts/VendorOnboarding/redux/actions";
import StitchLoading from "@ob/components/StitchLoading";
import {
  selectAuthFetching,
  selectAuthError,
} from "@ob/layouts/VendorOnboarding/redux/selectors/auth";
import { selectAllBanks, selectAllCards } from "../../redux/selectors/payDest";

export default function RelinkAccount() {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const apiFetching = useAppSelector(selectAuthFetching);
  const apiError = useAppSelector(selectAuthError);
  const cards = useAppSelector(selectAllCards);
  const bankAccounts = useAppSelector(selectAllBanks);

  const disabledCard = cards.find((card) => card.disabled);
  const disabledBankAccount = bankAccounts.find((bank) => bank.disabled);
  const allPaymentMethodsDisabled = [
    cards.length + bankAccounts.length > 0,
    cards.length === 0 || cards.every((card) => card.disabled),
    bankAccounts.length === 0 || bankAccounts.every((bank) => bank.disabled),
  ].every(Boolean);

  const [uiFetching, setUiFetching] = useState(false);

  const { message: errMsg } = apiError;

  const relinkPaymentMethod = () => {
    if (disabledCard) {
      dispatch(ob.payDest.deleteDisabledCard("/linked_accounts"));
    } else if (disabledBankAccount) {
      dispatch(ob.payDest.deleteDisabledBankAccount("/linked_accounts"));
    }
  };

  useEffect(() => {
    if (errMsg && uiFetching) {
      setUiFetching(false);
    } else if (!apiFetching && uiFetching) {
      setUiFetching(false);
    }
  }, [uiFetching, errMsg]);

  useEffect(() => {
    if (!allPaymentMethodsDisabled) {
      navigate("/linked_accounts");
    }
  }, [allPaymentMethodsDisabled]);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const jwt = params.get("auth");
    if (jwt) {
      setUiFetching(true);
      dispatch(ob.auth.getUserData(jwt, { redirect: "" }));
    }
  }, []);

  if (uiFetching) {
    return (
      <FadeInStack
        style={{ width: "100vw", height: "100%" }}
        alignItems="center"
        justifyContent="center"
      >
        <StitchLoading />
      </FadeInStack>
    );
  }

  if (apiError.status) {
    return (
      <FadeInStack
        sx={{
          justifyContent: "center",
          alignItems: "center",
          minHeight: "100vh",
          width: "100%",
          height: "100%",
          backgroundColor: "#FFF",
        }}
      >
        <Stack gap={5} alignItems="center">
          <Typography variant="h1" align="center">
            Oops.
          </Typography>
          <Typography variant="body1" align="center">
            {apiError.message || (
              <>
                Looks like something went wrong. <br /> Please try again.
              </>
            )}
          </Typography>
        </Stack>
      </FadeInStack>
    );
  }

  return (
    <FadeInStack
      alignItems="center"
      justifyContent="space-between"
      gap={3}
      sx={{
        maxWidth: "748px",
        width: "100%",
        height: "calc(100vh - 50px)",
        pb: 5,
        pl: 5,
        pr: 5,
        backgroundColor: "#FFF",
      }}
    >
      <Box sx={{ width: "100%" }} />
      <Stack alignItems="center" justifyContent="center" gap={5}>
        <Box>
          <img src={fingerPoint} alt="finger-point" />
        </Box>
        <Typography variant="h1">Link another account</Typography>
        {disabledCard ? (
          <Typography variant="body1" sx={{ mt: 3 }} align="center">
            {disabledCard.cardCompany} {disabledCard.mask} is unable to receive
            payouts. Link a different card, or bank account, to get set for
            payments.
            <br />
            <br />
            For more information, please reach out to your card issuer.
          </Typography>
        ) : (
          <Typography variant="body1" sx={{ mt: 3 }} align="center">
            {disabledBankAccount?.institutionName} {disabledBankAccount?.mask}{" "}
            is unable to receive payouts. Link a different account, or add a
            card, to get set for payments.
            <br />
            <br />
            For more information, please reach out to your bank.
          </Typography>
        )}
      </Stack>
      <Stack gap={2} sx={{ width: "100%" }}>
        <Button
          fullWidth
          variant="contained"
          color="primary"
          onClick={() => relinkPaymentMethod()}
        >
          Continue
        </Button>
      </Stack>
    </FadeInStack>
  );
}
