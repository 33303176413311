import { useEffect, useCallback } from "react";
import { useNavigate } from "react-router";
import { Stack, Typography, useTheme, Link } from "@mui/material";
import { FadeInStack } from "@ob/components/FadeComponents";
import StitchAsyncButton from "@ob/components/StitchAsyncButton";
import LocalErrorMsg from "@ob/components/LocalErrorMsg";
import GenericRetryError from "@ob/components/GenericRetryError";
import creditCardSvg from "@ob/assets/svgs/credit_card_purple.svg";
import { CheckOutlined } from "@ant-design/icons";
import { useAppDispatch, useAppSelector } from "@ob/redux/store";
import {
  selectConfigApiSuccess,
  selectKybConfigEnabled,
} from "@ob/layouts/VendorOnboarding/redux/selectors/config";
import va from "@ob/layouts/VendorOnboarding/redux/actions";
import {
  selectAuthSuccess,
  selectJWT,
  selectAuthFetching,
  selectAuthError,
  selectMerchantName,
} from "../../redux/selectors/auth";

export default function Authorize() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const jwt = useAppSelector(selectJWT);
  const merchantName = useAppSelector(selectMerchantName);
  const apiFetching = useAppSelector(selectAuthFetching);
  const apiError = useAppSelector(selectAuthError);
  const apiSuccess = useAppSelector(selectAuthSuccess);
  const configApiSuccess = useAppSelector(selectConfigApiSuccess);
  const kybConfigEnabled = useAppSelector(selectKybConfigEnabled);
  const theme = useTheme();

  if (!jwt) {
    dispatch(va.routes.redirect("/404"));
  }

  const handleAuthorize = () => {
    dispatch(va.auth.getUserData(jwt, { redirect: "/linked_accounts" }));
  };

  useEffect(() => {
    const useKyb = configApiSuccess && kybConfigEnabled;
    if (apiSuccess) {
      if (configApiSuccess) {
        if (useKyb) {
          navigate("/get_started");
        } else {
          navigate("/kyc/name");
        }
      }
    }
  }, [apiSuccess, configApiSuccess, kybConfigEnabled]);

  const clearAsyncState = useCallback(() => {
    dispatch(va.auth.apiSuccess(false));
    dispatch(va.auth.apiFetching(false));
    dispatch(
      va.auth.apiError({
        status: 0,
        message: "",
      }),
    );
    dispatch(va.config.getConfig());
  }, []);

  useEffect(() => {
    clearAsyncState();
    return () => clearAsyncState();
  }, []);

  return (
    <FadeInStack
      alignItems="center"
      justifyContent="space-between"
      sx={{
        maxWidth: "748px",
        p: 3,
        m: "auto",
        minHeight: "calc(100vh - 50px)",
      }}
      gap={5}
    >
      <Stack alignItems="center" justifyContent="flex-start">
        <Stack
          sx={{
            maxHeight: 200,
            maxWidth: 200,
            height: "20vh",
            width: "100%",
            p: 2,
          }}
          alignItems="center"
          justifyContent="center"
        >
          <img src={creditCardSvg} alt="Credit Card" />
        </Stack>
        <Typography variant="h1" textAlign="center" sx={{ my: 4 }}>
          {merchantName} partners with Stitch for payments.
        </Typography>
        <Stack alignItems="flex-start" gap={2}>
          <Stack direction="row" gap={2} alignItems="flex-start">
            <CheckOutlined style={{ fontSize: "24px" }} />
            <Typography variant="body1">
              Stitch helps you connect your debit card for fast and easy
              payouts.
            </Typography>
          </Stack>
          <Stack direction="row" gap={2}>
            <CheckOutlined style={{ fontSize: "24px" }} />
            <Typography variant="body1">
              Your financial data is safe, with identity verification and bank
              level security.
            </Typography>
          </Stack>
          <Stack direction="row" gap={2}>
            <CheckOutlined style={{ fontSize: "24px" }} />
            <Typography variant="body1">
              {merchantName} will not have access to your credentials.
            </Typography>
          </Stack>
        </Stack>
      </Stack>
      <Stack alignItems="center" sx={{ width: "100%" }}>
        <LocalErrorMsg error={apiError} />
        <GenericRetryError error={apiError} onClick={handleAuthorize} />
        <Typography
          variant="subtitle1"
          textAlign="center"
          sx={{
            mb: 2,
            color: theme.palette.secondary.main,
          }}
        >
          By clicking &quot;Continue&quot;, you agree to{" "}
          <Link
            href="https://www.withstitch.com/privacy-policy"
            target="_blank"
            rel="noreferrer noopener"
            style={{
              color: theme.palette.secondary.main,
              textDecoration: "underline",
            }}
          >
            {"Stitch's"} Privacy Policy
          </Link>{" "}
          and{" "}
          <Link
            href="https://www.withstitch.com/terms-of-service"
            target="_blank"
            rel="noreferrer noopener"
            style={{
              color: theme.palette.secondary.main,
              textDecoration: "underline",
            }}
          >
            Terms of Service
          </Link>
          .
        </Typography>
        <StitchAsyncButton
          buttonText="Authorize"
          variant="contained"
          color="primary"
          logoColor="white"
          onClick={handleAuthorize}
          success={apiSuccess}
          loading={apiFetching}
          loadingSize="small"
          loadingPosition={{ top: -31, left: 0 }}
        />
      </Stack>
    </FadeInStack>
  );
}
