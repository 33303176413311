import { ReactElement, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useAppSelector } from "@ob/redux/store";
import { selectLoggedIn } from "@ob/layouts/VendorOnboarding/redux/selectors/auth";
import {
  selectPhoneNumber,
  selectOTPValid,
} from "@ob/layouts/VendorOnboarding/redux/selectors/phone";
import ErrorBoundary from "@ob/components/ErrorBoundary";

type Props = {
  children: ReactElement | null;
};

function AuthGuard(props: Props) {
  const isLoggedIn = useAppSelector(selectLoggedIn);
  const otpValid = useAppSelector(selectOTPValid);
  const phoneNumber = useAppSelector(selectPhoneNumber);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (!isLoggedIn) {
      navigate("/session_expired", {
        state: {
          from: location.pathname,
        },
        replace: true,
      });
    } else if (!otpValid) {
      if (phoneNumber) {
        navigate("/phone_verification", {
          state: {
            from: location.pathname,
          },
          replace: true,
        });
      } else {
        navigate("/phone", {
          state: {
            from: location.pathname,
          },
          replace: true,
        });
      }
    }
  }, [phoneNumber, isLoggedIn, otpValid]);

  return <ErrorBoundary>{props.children}</ErrorBoundary>;
}

export default AuthGuard;
