import { ErrorPayload } from "@ob/api/utils/safeFetch";
import { VendorStateType } from "@ob/layouts/VendorOnboarding/types";
import { createSelector } from "@reduxjs/toolkit";
import { FeesPayloadType } from "@ob/layouts/VendorOnboarding/types/config";

export const selectConfigFetching = createSelector(
  (state: { vendor: VendorStateType }) => state.vendor,
  (vendor): boolean => vendor.config.fetching,
);

export const selectConfigError = createSelector(
  (state: { vendor: VendorStateType }) => state.vendor,
  (vendor): ErrorPayload => vendor.config.error,
);

export const selectConfigApiSuccess = createSelector(
  (state: { vendor: VendorStateType }) => state.vendor,
  (vendor): boolean => vendor.config.success,
);

export const selectKybConfigEnabled = createSelector(
  (state: { vendor: VendorStateType }) => state.vendor,
  (vendor): boolean => vendor.config.data.kybEnabled,
);

export const selectCardFees = createSelector(
  (state: { vendor: VendorStateType }) => state.vendor,
  (vendor): FeesPayloadType | null => vendor.config.data.cardFees,
);

export const selectBankFees = createSelector(
  (state: { vendor: VendorStateType }) => state.vendor,
  (vendor): FeesPayloadType | null => vendor.config.data.bankFees,
);
