import { useEffect } from "react";
import { Stack, Box, Typography } from "@mui/material";
import { ClockCircleOutlined } from "@ant-design/icons";
import { useAppSelector, useAppDispatch } from "@ob/redux/store";
import {
  selectAuthFetching,
  selectJwtIsSelfServe,
  selectAuthSuccess,
  selectAuthError,
  selectMerchantName,
} from "@ob/layouts/VendorOnboarding/redux/selectors/auth";
import va from "@ob/layouts/VendorOnboarding/redux/actions";
import StitchAsyncButton from "@ob/components/StitchAsyncButton";
import StitchAlertIcon from "@ob/components/StitchAlertIcon";
import StitchErrorIcon from "@ob/components/StitchErrorIcon";
import { FadeInStack } from "@ob/components/FadeComponents";

export default function SessionExpired() {
  const dispatch = useAppDispatch();
  const isSelfServe = useAppSelector(selectJwtIsSelfServe);
  const apiFetching = useAppSelector(selectAuthFetching);
  const apiSuccess = useAppSelector(selectAuthSuccess);
  const apiError = useAppSelector(selectAuthError);
  const merchantName = useAppSelector(selectMerchantName);

  useEffect(() => {
    dispatch(va.auth.apiSuccess(false));
    dispatch(va.auth.apiError({ message: "", status: 0 }));
    dispatch(va.auth.apiFetching(false));
  }, []);

  if (apiError.status) {
    return (
      <FadeInStack
        alignItems="center"
        justifyContent="center"
        sx={{ height: "calc(100vh - 90px)", p: 3 }}
        gap={3}
      >
        <StitchErrorIcon />
        <Stack gap={2} alignItems="center">
          <Typography variant="h1">Session Error</Typography>
          <Typography variant="body1" sx={{ mt: 6 }} align="center">
            Your session could not be refreshed.
          </Typography>
          <Typography variant="body1" align="center">
            Contact {merchantName} to receive a new login link.
          </Typography>
        </Stack>
      </FadeInStack>
    );
  }

  if (apiSuccess) {
    return (
      <FadeInStack
        alignItems="center"
        justifyContent="center"
        sx={{ height: "calc(100vh - 90px)", p: 3 }}
        gap={3}
      >
        <StitchAlertIcon />
        <Stack gap={1} alignItems="center">
          <Typography variant="h1">Check Email</Typography>
          <Typography variant="body1" sx={{ mt: 6 }} align="center">
            Please check your email for a new login link.
          </Typography>
          <Typography variant="body1" align="center">
            If your email was recognized, you will receive a new link shortly.
          </Typography>
        </Stack>
      </FadeInStack>
    );
  }

  return (
    <FadeInStack
      alignItems="center"
      justifyContent="center"
      sx={{ height: "calc(100vh - 90px)", p: 3 }}
      gap={3}
    >
      <Box sx={{ padding: 4 }}>
        <ClockCircleOutlined style={{ fontSize: "36px" }} />
      </Box>
      <Typography variant="h1">Session expired</Typography>
      <Typography variant="body1" sx={{ mt: 6 }} align="center">
        Your session timed out. Please refresh your login to continue.
      </Typography>
      {isSelfServe && (
        <StitchAsyncButton
          buttonText="Refresh login"
          variant="contained"
          color="primary"
          logoColor="white"
          onClick={() => dispatch(va.auth.sendReloginLink())}
          success={apiSuccess}
          loading={apiFetching}
          loadingSize="small"
          loadingPosition={{ top: -31, left: 0 }}
        />
      )}
    </FadeInStack>
  );
}
