import { CreditCardOutlined } from "@ant-design/icons";
import { Stack, Typography, useTheme } from "@mui/material";
import { selectCardFees } from "@ob/layouts/VendorOnboarding/redux/selectors/config";
import {
  isCardExpired,
  isCardIneligible,
} from "@ob/layouts/VendorOnboarding/utils/cards";
import { useAppSelector } from "@ob/redux/store";
import { CreditCardDbType } from "@ob/redux/types/dbTypes";
import formatCurrency from "@ob/utils/formatCurrency";
import formatPercent from "@ob/utils/formatPercent";

type Props = {
  card: CreditCardDbType;
};
export default function CardDetails(props: Props) {
  const theme = useTheme();
  const fees = useAppSelector(selectCardFees);
  const cardExpired = isCardExpired(props.card.expiration);
  const cardIneligible = isCardIneligible(props.card);

  const feesLabel = fees
    ? [
        `${formatPercent(Number(fees.rate || "0") / 100)} Fee`,
        `(Min ${formatCurrency(fees.min)}`,
        `Max ${formatCurrency(fees.max)}) per txfr.`,
      ].join(" ")
    : "";
  return (
    <Stack
      key={props.card.id}
      direction="row"
      alignItems="center"
      justifyContent="space-between"
      sx={{
        width: "100%",
        pt: 2,
        minHeight: 5,
        mt: 4,
      }}
    >
      <Stack direction="row" alignItems="center">
        <CreditCardOutlined style={{ fontSize: "24px" }} />
        <Stack sx={{ ml: 2 }}>
          <Typography variant="body1">
            {props.card.cardCompany} {props.card.mask}
          </Typography>
          <Typography variant="body1" color="secondary">
            Exp: {props.card.expiration}
          </Typography>
          <Typography color="secondary" align="left">
            {feesLabel}
          </Typography>
        </Stack>
      </Stack>
      <Stack direction="row" gap={1}>
        <Stack>
          <div
            style={{
              display: !props.card.active ? "block" : "none",
              backgroundColor: theme.palette.error.main,
              color: "white",
              padding: "5px 2px",
              borderRadius: "5px",
              width: "75px",
              fontSize: "12px",
              lineHeight: "16px",
              textAlign: "center",
              fontFamily: "CircularBold",
              textTransform: "capitalize",
            }}
          >
            inactive
          </div>
        </Stack>
        <Stack>
          <div
            style={{
              display: cardIneligible ? "block" : "none",
              backgroundColor: theme.palette.error.main,
              color: "white",
              padding: "5px 2px",
              borderRadius: "5px",
              width: "75px",
              fontSize: "12px",
              lineHeight: "16px",
              textAlign: "center",
              fontFamily: "CircularBold",
              textTransform: "capitalize",
            }}
          >
            ineligible
          </div>
        </Stack>
        <Stack>
          <div
            style={{
              display: cardExpired && !cardIneligible ? "block" : "none",
              backgroundColor: theme.palette.error.main,
              color: "white",
              padding: "5px 2px",
              borderRadius: "5px",
              width: "75px",
              fontSize: "12px",
              lineHeight: "16px",
              textAlign: "center",
              fontFamily: "CircularBold",
              textTransform: "capitalize",
            }}
          >
            expired
          </div>
        </Stack>
      </Stack>
    </Stack>
  );
}
